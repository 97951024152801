import React, { useEffect, useState } from 'react';

const Settings: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>(
    localStorage.getItem('theme') === 'dark' || (!localStorage.getItem('theme') && window.matchMedia('(prefers-color-scheme: dark)').matches)
      ? 'dark'
      : 'light'
  );

  useEffect(() => {
    document.documentElement.classList.toggle('dark', theme === 'dark');
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = (): void => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  return (
    <div className={`min-h-screen p-4 ${theme === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <div className="max-w-3xl mx-auto">
        <h1 className="text-2xl font-bold mb-4">Settings</h1>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Appearance</h2>
          <button
            className="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-600 focus:outline-none"
            onClick={toggleTheme}
          >
            Toggle Theme to {theme === 'light' ? 'Dark' : 'Light'} Mode
          </button>
        </section>

        {/* Add other settings sections here */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Account</h2>
          <p>Manage your account settings here. (Coming soon!)</p>
        </section>

        <section className="mb-6">
          <h2 className="text-xl font-semibold mb-2">Notifications</h2>
          <p>Customize your notification preferences. (Coming soon!)</p>
        </section>

      </div>
    </div>
  );
};

export default Settings;
