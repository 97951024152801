import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import Home from './pages/Home';
import EmailSignIn from './pages/EmailSignIn';
import VerifyEmail from './pages/VerifyEmail';
import SetupPin from './pages/SetupPin';
import VerifyPin from './pages/VerifyPin';
import Dashboard from './pages/Dashboard';
import Transfer from './pages/Transfer';
import Settings from './pages/Settings'
import { useAuthStore } from './store/authStore';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated, user } = useAuthStore();
  const location = useLocation();
  
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (isAuthenticated && !user?.hasPinSetup && location.pathname !== '/auth/setup-passcode') {
    return <Navigate to="/auth/setup-passcode" />;
  }

  if (user?.hasPinSetup && 
      location.pathname !== '/auth/passcode' &&
      location.pathname !== '/auth/setup-passcode' && 
      !sessionStorage.getItem('pin-verified')) {
    return <Navigate to="/auth/passcode" state={{ returnTo: location.pathname }} />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <BrowserRouter>
      <Toaster position="top-center" />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/auth/email" element={<EmailSignIn />} />
        <Route path="/auth/verify" element={<VerifyEmail />} />
        <Route 
          path="/auth/setup-passcode" 
          element={
            <ProtectedRoute>
              <SetupPin />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/auth/passcode"
          element={
            <ProtectedRoute>
              <VerifyPin />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } 
        />
      <Route 
          path="/transfer" 
          element={
            <ProtectedRoute>
              <Transfer />
            </ProtectedRoute>
          } 
        />
      <Route 
          path="/dashboard?t=more" 
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
