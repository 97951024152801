import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCFG9O82IaLAKGNSEKx81lF_zdz-sRwWrI",
  authDomain: "nkay-data.firebaseapp.com",
  projectId: "nkay-data",
  storageBucket: "nkay-data.firebasestorage.app",
  messagingSenderId: "659703984559",
  appId: "1:659703984559:web:adf8d875a94ae0e092ff5a"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const idToken = await result.user.getIdToken();
    return { idToken, email: result.user.email };
  } catch (error) {
    console.error('Google sign in error:', error);
    throw error;
  }
};