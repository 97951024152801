import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, Fingerprint } from 'lucide-react';
import toast from 'react-hot-toast';
import api from '../lib/api';
import { useAuthStore } from '../store/authStore';
import { useBiometricAuth } from '../hooks/useBiometricAuth';

export default function SetupPin() {
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useAuthStore();
  const { isBiometricsAvailable, registerBiometric } = useBiometricAuth();

  const handlePinInput = (value: string) => {
    if (pin.length < 4) {
      const newPin = pin + value;
      setPin(newPin);
      
      if (newPin.length === 4) {
        handlePinSubmit(newPin);
      }
    }
  };

  const handlePinSubmit = async (finalPin: string) => {
    setLoading(true);
    try {
      await api.post('/auth/setup-pin', {
        userId: user.id,
        pin: finalPin
      });
      setUser({ ...user, hasPinSetup: true });
      toast.success('PIN setup successful');

      // After PIN setup, offer biometric registration if available
      if (isBiometricsAvailable) {
        const shouldSetupBiometrics = window.confirm(
          'Would you like to enable fingerprint authentication for faster login?'
        );
        
        if (shouldSetupBiometrics) {
          await handleBiometricSetup();
        }
      }

      //navigate('/dashboard');
    } catch (error) {
      toast.error('Failed to setup PIN');
      setPin('');
    } finally {
      setLoading(false);
    }
  };

  const handleBiometricSetup = async () => {
    try {
      await registerBiometric(user.id);
      toast.success('Fingerprint authentication enabled');
      setUser({ ...user, hasBiometricSetup: true });
    } catch (error) {
      toast.error('Failed to setup fingerprint authentication');
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    const key = event.key;
    if (/^[0-9]$/.test(key)) {
      handlePinInput(key);
    } else if (key === 'Backspace') {
      setPin(prev => prev.slice(0, -1));
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [pin]);

  return (
    <div className="min-h-screen bg-black text-white p-4 flex flex-col">
      <div className="max-w-md mx-auto w-full pt-8">
        <div className="flex justify-between items-center mb-8">
          <button onClick={() => navigate(-1)}>
            <X className="w-6 h-6" />
          </button>
          <h1 className="text-xl font-semibold">Setup PIN</h1>
          <div className="w-6" />
        </div>

        {/* PIN Display */}
        <div className="mb-8">
          <div className="flex justify-center space-x-4 mb-4">
            {[...Array(4)].map((_, i) => (
              <div
                key={i}
                className={`w-3 h-3 rounded-full ${
                  i < pin.length ? 'bg-white' : 'bg-gray-600'
                }`}
              />
            ))}
          </div>
          <p className="text-center text-gray-400">
            Enter a 4-digit PIN
          </p>
        </div>

        {/* Number Pad */}
        <div className="grid grid-cols-3 gap-4 max-w-xs mx-auto">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
            <button
              key={num}
              onClick={() => handlePinInput(num.toString())}
              className="w-16 h-16 rounded-full bg-gray-800 hover:bg-gray-700 flex items-center justify-center text-2xl font-semibold"
              disabled={loading}
            >
              {num}
            </button>
          ))}
          <div className="w-16 h-16" />
          <button
            onClick={() => handlePinInput('0')}
            className="w-16 h-16 rounded-full bg-gray-800 hover:bg-gray-700 flex items-center justify-center text-2xl font-semibold"
            disabled={loading}
          >
            0
          </button>
          <button
            onClick={() => setPin(prev => prev.slice(0, -1))}
            className="w-16 h-16 rounded-full bg-gray-800 hover:bg-gray-700 flex items-center justify-center"
            disabled={loading}
          >
            ←
          </button>
        </div>

        {isBiometricsAvailable && (
          <div className="mt-8 text-center">
            <p className="text-gray-400 text-sm mb-2">
              You'll be able to enable fingerprint authentication after setting up your PIN
            </p>
            <Fingerprint className="w-6 h-6 mx-auto text-blue-500" />
          </div>
        )}
      </div>
    </div>
  );
}