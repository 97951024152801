import React, { useState } from "react";
import { Link } from "react-router-dom";
import FormComponent from '../components/Data';

interface QuickAccessItemProps {
  id: string;
  to: string;
  icon: React.ReactNode;
  label: string;
  notification?: boolean;
  onClick: (id: string) => void; // Add a prop for the click handler
}

const QuickAccessItem: React.FC<QuickAccessItemProps> = ({
  id,
  to,
  icon,
  label,
  notification = false,
  onClick,  // Destructure onClick from props
}) => {
  return (
    <div id={id} onClick={() => onClick(id)}>  {/* Call onClick when item is clicked */}
      <Link className="block relative" to={to}>
        {notification && (
          <span className="flex h-1.5 w-3 absolute top-2 right-2 z-10">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-alertGreen opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-3.5 bg-alertGreen"></span>
          </span>
        )}
        <div className="space-x-2 relative w-[5.25rem] font-poppins shadow-button dark:bg-n0/5 bg-n0 dark:shadow-sm rounded overflow-x-hidden">
          <div className="py-2 block space-y-1 text-center">
            <div className="flex items-center justify-center text-[#0163A4]">
              {icon}
            </div>
            <p className="flex center gap-2 font-medium dark:text-n05 text-p1/90 leading-normal text-[0.725rem] text-n7 tracking-widest">
              {label}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};

const QuickAccess: React.FC = () => {
  const [selectedId, setSelectedId] = useState("data");

  const handleItemClick = (id: string) => {
    setSelectedId(id); // Update the selectedId when an item is clicked
    console.log(`Item clicked: ${id}`); // You can add any additional action here
  };

  const items = [
    {
      id: "data",
      to: "/dashboard?qs=data",
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 23.5329V13.0333C4 10.0464 4 8.55292 4.58129 7.41205C5.09263 6.40852 5.90852 5.59263 6.91205 5.08129C8.05292 4.5 9.54639 4.5 12.5333 4.5H19.4667C22.4536 4.5 23.9471 4.5 25.088 5.08129C26.0915 5.59263 26.9073 6.40852 27.4187 7.41205C28 8.55292 28 10.0464 28 13.0333V14.6333C28 17.6203 28 19.1137 27.4187 20.2547C26.9073 21.2581 26.0915 22.074 25.088 22.5853C23.9471 23.1667 22.4536 23.1667 19.4667 23.1667H13.8123C13.1439 23.1667 12.8098 23.1667 12.4962 23.2437C12.2182 23.312 11.9531 23.4247 11.7109 23.5772C11.4378 23.7495 11.2057 23.9899 10.7417 24.4708L9.50288 25.7548C7.7828 27.5376 6.92275 28.4291 6.18189 28.4967C5.53931 28.5553 4.90788 28.3004 4.48619 27.812C4 27.2489 4 26.0103 4 23.5329Z"
            className="fill-p1"
          />
          <path
            d="M10.6668 9.83325C9.93046 9.83325 9.3335 10.4302 9.3335 11.1666C9.3335 11.903 9.93046 12.4999 10.6668 12.4999H21.3335C22.0699 12.4999 22.6668 11.903 22.6668 11.1666C22.6668 10.4302 22.0699 9.83325 21.3335 9.83325H10.6668Z"
            className="fill-n05"
          />
          <path
            d="M10.6668 15.1667C9.93046 15.1667 9.3335 15.7637 9.3335 16.5001C9.3335 17.2365 9.93046 17.8334 10.6668 17.8334H14.6668C15.4032 17.8334 16.0002 17.2365 16.0002 16.5001C16.0002 15.7637 15.4032 15.1667 14.6668 15.1667H10.6668Z"
            className="fill-n05"
          />
        </svg>
      ),
      label: "Data",
      notification: true,
    },
    {
      id: "airtime",
      to: "/dashboard?qs=airtime",
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7206 23.0869L7.49187 20.9421C4.35932 20.1256 4.11187 15.7733 7.13169 14.607L31.546 5.17793C33.5968 4.38586 35.614 6.403 34.8218 8.45383L25.3928 32.8681C24.2265 35.8879 19.8743 35.6404 19.0578 32.5079L16.913 24.2792C16.7608 23.6952 16.3047 23.2391 15.7206 23.0869Z" class="dark:fill-n05 fill-p1/20"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M16.4785 23.5216L34.2497 5.75024C34.9114 6.41186 35.2176 7.42884 34.8216 8.45424L25.3926 32.8686C24.2262 35.8884 19.8741 35.6409 19.0576 32.5084L16.9127 24.2797C16.8366 23.9876 16.6846 23.7276 16.4785 23.5216Z" fill="#0163A4"
            className="flex items-center justify-center text-[#0163A4]"
          />
        </svg>
      ),
      label: "Airtime",
    },
    {
        id: "electricity",
        to: "/dashboard?qs=electricity",
        icon: (
          <svg
            width="24"
            height="30"
            viewBox="0 0 22 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
            d="M16 20.073C19.1883 18.2287 21.3333 14.7815 21.3333 10.8334C21.3333 4.94231 16.5577 0.166687 10.6667 0.166687C4.77563 0.166687 0 4.94231 0 10.8334C0 14.7815 2.14505 18.2287 5.33333 20.073V21.5C5.33333 22.2364 5.93029 22.8334 6.66667 22.8334H14.6667C15.4031 22.8334 16 22.2364 16 21.5V20.073Z" class="dark:fill-n05 fill-p1/20"></path><path d="M6.66667 22.8334H14.6667L14.6667 24.1667C14.6667 25.6395 13.4728 26.8334 12 26.8334H9.33333C7.86057 26.8334 6.66667 25.6395 6.66667 24.1667L6.66667 22.8334Z" fill="#0163A4"></path><path d="M8.9428 11.2239C8.42213 10.7032 7.57789 10.7032 7.05719 11.2239C6.53649 11.7446 6.53649 12.5888 7.05719 13.1095L9.33333 15.3856V22.8334H12V15.3856L14.2761 13.1095C14.7968 12.5888 14.7968 11.7446 14.2761 11.2239C13.7555 10.7032 12.9112 10.7032 12.3905 11.2239L10.6667 12.9478L8.9428 11.2239Z" fill="#0163A4"
            className=""
            />
            </svg>
        ),
        label: "Electricity",
      },
      {
        id: "tvsub",
        to: "/dashboard?qs=tvsub",
        icon: (
          <svg
            width="30"
            height="30"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
            d="M11.667 33.3334C11.667 32.4129 12.4132 31.6667 13.3337 31.6667H26.667C27.5875 31.6667 28.3337 32.4129 28.3337 33.3334C28.3337 34.2539 27.5875 35.0001 26.667 35.0001H13.3337C12.4132 35.0001 11.667 34.2539 11.667 33.3334Z" class="fill-p1 "></path><path d="M13 28.3333C10.1997 28.3333 8.7996 28.3333 7.73005 27.7883C6.78923 27.309 6.02433 26.5442 5.54497 25.6033C5 24.5337 5 23.1337 5 20.3333V13C5 10.1997 5 8.7996 5.54497 7.73005C6.02433 6.78923 6.78923 6.02433 7.73005 5.54497C8.7996 5 10.1997 5 13 5H27C29.8003 5 31.2003 5 32.27 5.54497C33.2108 6.02433 33.9757 6.78923 34.455 7.73005C35 8.7996 35 10.1997 35 13V20.3333C35 23.1337 35 24.5337 34.455 25.6033C33.9757 26.5442 33.2108 27.309 32.27 27.7883C31.2003 28.3333 29.8003 28.3333 27 28.3333H13Z" class="fill-p1"></path><path d="M11.6667 10C10.7462 10 10 10.7462 10 11.6667C10 12.5871 10.7462 13.3333 11.6667 13.3333H23.3333C24.2538 13.3333 25 12.5871 25 11.6667C25 10.7462 24.2538 10 23.3333 10H11.6667Z" class="fill-n05"></path><path d="M11.6667 16.6667C10.7462 16.6667 10 17.4129 10 18.3334C10 19.2539 10.7462 20.0001 11.6667 20.0001H15C15.9205 20.0001 16.6667 19.2539 16.6667 18.3334C16.6667 17.4129 15.9205 16.6667 15 16.6667H11.6667Z" class="fill-n05"
            className=""
            />
          </svg>
        ),
        label: "TV",
      },
  ];
  
  return (
    <div className="relative flex items-center gap-2 p-4 mx-auto max-w-xl no-scrollbar overflow-x-auto">
      {items.map((item) => (
        <QuickAccessItem
          key={item.id}
          id={item.id}
          to={item.to}
          icon={item.icon}
          label={item.label}
          notification={item.notification}
          onClick={handleItemClick} // Pass the click handler to each item
        />
      ))}
    </div>
  );
};

export default QuickAccess;
