import React from 'react';

const Transfer: React.FC = () => {
  React.useEffect(() => {
    // Handle theme logic
    try {
      const root = document.documentElement;
      const theme = localStorage.getItem('theme');

      root.classList.remove('light', 'dark');

      if (theme === 'system' || (!theme && true)) {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        if (prefersDark.matches) {
          root.style.colorScheme = 'dark';
          root.classList.add('dark');
        } else {
          root.style.colorScheme = 'light';
          root.classList.add('light');
        }
      } else if (theme) {
        root.classList.add(theme);
        if (theme === 'light' || theme === 'dark') {
          root.style.colorScheme = theme;
        }
      }
    } catch (e) {
      console.error('Error applying theme:', e);
    }
  }, []);

  return (
    <div className="flex flex-col font-poppins h-full">
      {/* Header */}
      <div className="flex backdrop-blur-sm sticky top-0 items-center space-x-2 p-4 px-2 border-b dark:border-n5/10 border-n2 w-full">
        <div className="absolute">
          <button className="flex-shrink-0 flex h-8 w-8 dark:bg-n5/5 bg-n1 items-center justify-center rounded-full">
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="[&_path]:dark:fill-n0"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.94281 0.390524C7.46351 0.911223 7.46351 1.75544 6.94281 2.27614L3.21895 6L6.94281 9.72386C7.46351 10.2446 7.46351 11.0888 6.94281 11.6095C6.42211 12.1302 5.57789 12.1302 5.05719 11.6095L0.390524 6.94281C-0.130175 6.42211 -0.130175 5.57789 0.390524 5.05719L5.05719 0.390524C5.57789 -0.130175 6.42211 -0.130175 6.94281 0.390524Z"
                fill="#353945"
              ></path>
            </svg>
          </button>
        </div>
        <div className="flex items-center justify-center w-full">
          <span className="text-body2 font-poppins tracking-wide font-bold truncate">Transfer</span>
        </div>
      </div>

      {/* Balance Section */}
      <div className="pt-8 text-center">
        <h2 className="dark:text-n2 tracking-wide mb-2 text-body font-poppins leading-normal text-n6 font-bold">
          ₦0.00
        </h2>
        <p className="leading-normal font-poppins text-caption2 font-normal text-n7 dark:text-n2">
          Quickly transfer funds to another user on nkaydata.
        </p>
      </div>

      {/* Form Section */}
      <div className="w-full p-4 max-w-lg mx-auto">
        <form className="space-y-4 mt-2 p-4 relative rounded-xl bg-n0 dark:shadow-none shadow-button dark:bg-n5/5">
          {/* Email Field */}
          <div>
            <p className="text-[12px] font-poppins tracking-wider text-n7 dark:text-n05/90 pb-1">Email</p>
            <label
              htmlFor="email"
              className="bg-n05 h-14 px-4 focus-within:[&]:dark:bg-n5/10 dark:bg-n5/5 flex rounded-xl font-medium relative font-poppins"
            >
              <input
                id="email"
                className="bg-[transparent] w-full outline-none tracking-wider leading-none"
                autoComplete="off"
                type="email"
                autoFocus
              />
            </label>
          </div>

          {/* Amount Field */}
          <div>
            <p className="text-[12px] font-poppins tracking-wider text-n7 dark:text-n05/90 pb-1">Amount</p>
            <div className="flex flex-col gap-2">
              <label
                htmlFor="amount"
                className="bg-n05 h-14 px-4 focus-within:[&]:dark:bg-n5/10 dark:bg-n5/10 flex items-center rounded font-medium relative font-poppins"
              >
                <div className="font-poppins pr-1 font-medium mt-0.5">₦</div>
                <input
                  id="amount"
                  className="bg-[transparent] w-full outline-none tracking-wider leading-none"
                  autoComplete="off"
                  type="tel"
                />
              </label>
            </div>
          </div>

          {/* Continue Button */}
          <button
            disabled
            className="border-2 pointer-events-none text-p1/50 border-p1/30 flex w-full h-14 center rounded-lg"
          >
            <span className="text-caption font-poppins font-bold">Continue</span>
            <div className="h-14 w-14 flex center">
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="[&_path]:fill-p1/70"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.5909 7.26521C14.9968 6.8906 15.6294 6.9159 16.004 7.32172L19.2348 10.8217C19.5884 11.2047 19.5884 11.7952 19.2348 12.1782L16.004 15.6783C15.6294 16.0841 14.9968 16.1094 14.591 15.7348C14.1851 15.3602 14.1598 14.7276 14.5344 14.3217L16.216 12.5L6.5 12.5C5.94771 12.5 5.5 12.0523 5.5 11.5C5.5 10.9477 5.94771 10.5 6.5 10.5L16.216 10.5L14.5344 8.67829C14.1598 8.27247 14.1851 7.63981 14.5909 7.26521Z"
                ></path>
              </svg>
            </div>
          </button>
        </form>
      </div>
    </div>
  );
};

export default Transfer;
