import React, { useState } from 'react';
import { Link, NavLink as RouterNavLink } from "react-router-dom";
import { useInactivityTimer } from '../hooks/useInactivityTimer';
import QuickAccess from "./QuickAccess";
import Data from "../components/Data";

const Dashboard: React.FC = () => {
  useInactivityTimer();
  React.useEffect(() => {
    try {
      const root = document.documentElement;
      root.classList.remove('light', 'dark');
      const theme = localStorage.getItem('theme');

      if (theme === 'system' || (!theme && true)) {
        const mediaQuery = '(prefers-color-scheme: dark)';
        const mql = window.matchMedia(mediaQuery);

        if (mql.media !== mediaQuery || mql.matches) {
          root.style.colorScheme = 'dark';
          root.classList.add('dark');
        } else {
          root.style.colorScheme = 'light';
          root.classList.add('light');
        }
      } else if (theme) {
        root.classList.add(theme);
      }

      if (theme === 'light' || theme === 'dark') {
        root.style.colorScheme = theme;
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  return (
    <div id="__next">
      <div className="space-y-4">
        {/* Header */}
        <div className="flex w-full p-4 justify-between fixed top-0 z-20 dark:bg-n8/90 bg-n05/90 backdrop-filter backdrop-blur-sm">
          <div className="flex">
            <a href="/dashboard?t=more">
              <div className="relative -z-10 overflow-hidden rounded-full flex items-center justify-center" style={{width: '40px', height: '40px'}}>
                <img
                  alt="Lexis Carl"
                  src="https://lh3.googleusercontent.com/a/ACg8ocIjXqVjdOwFNLYbHARUfbWd0-VdcyPoPp97bugSMW88A48S5Q=s96-c"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              </div>
            </a>
            <div className="mx-4 pt-1 font-poppins space-y-1">
              <p className="text-[0.75rem] font-medium leading-none tracking-wider">
                <span className="text-n6 dark:text-n05">Good afternoon</span>
              </p>
              <p className="text-[0.78rem] leading-none font-semibold tracking-wider text-[#1F2C37] dark:text-n0">
                Lexis
              </p>
            </div>
          </div>

          <div className="flex font-poppins text-[0.75rem] gap-2 font-normal items-center space-x-4 relative">
            <div className="p-2 bg-p1 text-n0 flex center rounded-full rounded-br">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M4.804 21.644A6.707 6.707 0 006 21.75a6.721 6.721 0 003.583-1.029c.774.182 1.584.279 2.417.279 5.322 0 9.75-3.97 9.75-9 0-5.03-4.428-9-9.75-9s-9.75 3.97-9.75 9c0 2.409 1.025 4.587 2.674 6.192.232.226.277.428.254.543a3.73 3.73 0 01-.814 1.686.75.75 0 00.44 1.223Z" clipRule="evenodd" />
              </svg>
              <span className="inline-flex tracking-wider mx-1 font-medium">Support</span>
            </div>
          </div>
        </div>

        <div className="h-14" />

        {/* Main Content */}
        <div className="px-4 mx-auto max-w-xl space-y-2 relative">
          {/* Currency Section */}
          <div className="rounded-2xl py-2 px-0.5">
            <div className="text-caption2 font-poppins dark:text-n05 text-black font-medium select-none tracking-wider flex gap-2 items-center">
              <div className="flex gap-1.5 items-center relative">
                <svg width="16" height="16" viewBox="0 0 16 16" className="rounded-full size-4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="5.33333" height="16" fill="#009A49"/>
                  <rect x="5.33337" width="5.33333" height="16" fill="white"/>
                  <rect x="10.6666" width="5.33333" height="16" fill="#009A49"/>
                </svg>
                <span>Nigerian Naira</span>
              </div>
            </div>
            <div className="text-body tracking-wider font-poppins flex items-center font-bold dark:text-n2 text-n7 select-none">
              <span className="text-body mr-0.5 font-bold blur-0 text-n8 dark:text-n0/40">₦</span>
              <span></span>
            </div>
          </div>

          {/* Action Buttons */}
        <div className="flex center gap-2 font-poppins">
          <Link to="/transfer" className="flex h-12 shadow-button dark:shadow-none bg-n0 dark:bg-n0/5 center pr-6 flex-1 rounded text-p1 dark:text-n0">
            <div className="h-12 w-12 flex center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm.53 5.47a.75.75 0 00-1.06 0l-3 3a.75.75 0 101.06 1.06l1.72-1.72v5.69a.75.75 0 001.5 0v-5.69l1.72 1.72a.75.75 0 101.06-1.06l-3-3z" clipRule="evenodd" />
              </svg>
            </div>
            <span className="text-caption2 font-semibold">Transfer</span>
          </Link>

            <span className="flex h-12 bg-n0 shadow-button dark:shadow-none dark:bg-n0/10 center pr-6 flex-[1.5] rounded text-p1 dark:text-n0">
              <div className="h-12 w-12 flex center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z" clipRule="evenodd" />
                </svg>
              </div>
              <span className="text-caption2 font-semibold">Add Money</span>
            </span>
          </div>
        </div>

        {/* Quick Access Section */}
        <div className="px-4 mx-auto max-w-xl">
          <div className="p-4 rounded rounded-b-none bg-n0 dark:bg-n5/10">
            <label className="flex mb-2 items-center tracking-wider justify-between font-poppins text-caption2 text-p1 dark:text-n05 font-semibold dark:font-medium">
              <span className="flex items-center gap-1">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4ADE80" className="w-5 h-5">
                    <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z" clipRule="evenodd" />
                  </svg>
                </span>
                Quick Access
              </span>
            </label>

          {/* Quick Access Items */}
        <div className="relative p-1 dark:px-0 flex items-center overflow-x-auto gap-2 font-poppins no-scrollbar">
          <div className="flex items-start ml-[-15px]"> {/* Adjust the margin-left as needed */}
            <QuickAccess />
          </div>
        </div>
      </div>
    </div>

        {/* Transactions Section */}
        <div className="px-4 mx-auto max-w-xl">
          <div className="text-left my-4 space-y-[0.5px] font-poppins rounded tracking-wider leading-relaxed">
            <div className="flex dark:bg-[#292929] bg-n0 justify-between items-center tracking-wider p-4 px-6">
              <p className="font-normal text-caption2 dark:text-n1">Transactions</p>
              <a className="tracking-wide text-caption2 font-semibold" href="/dashboard?t=history">View all</a>
            </div>

            {/* No Transactions Message */}
            <div className="text-center font-poppins px-6 gap-3 flex flex-col max-w-xs mx-auto items-center mb-8 text-n0/40">
              <div className="p-4 rounded-full dark:bg-n5/5 mb-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                  <path fillRule="evenodd" d="M6.912 3a3 3 0 00-2.868 2.118l-2.411 7.838a3 3 0 00-.133.882V18a3 3 0 003 3h15a3 3 0 003-3v-4.162c0-.299-.045-.596-.133-.882l-2.412-7.838A3 3 0 0017.088 3H6.912zm13.823 9.75-2.213-7.191A1.5 1.5 0 0017.088 4.5H6.912a1.5 1.5 0 00-1.434 1.059L3.265 12.75H6.11a3 3 0 012.684 1.658l.256.513a1.5 1.5 0 001.342.829h3.218a1.5 1.5 0 001.342-.83l.256-.512a3 3 0 012.684-1.658h2.844z" clipRule="evenodd" />
                </svg>
              </div>
              <h3 className="text-body15 tracking-wider font-semibold">No transaction yet.</h3>
              <p className="text-caption leading-normal font-poppins font-normal tracking-wider">
                Discover your most recent transactions here.
              </p>
            </div>
          </div>
        </div>

        {/* Bottom Navigation */}
        <div className="h-14" />
        <div className="flex items-center z-20 fixed bottom-2 inset-x-2 rounded bg-n05 dark:bg-n8/95 backdrop-blur-sm">
          <div className="absolute -z-10 inset-0 bg-n05 dark:bg-n5/5 rounded" />
          <NavLink href="/dashboard" label="Home" active icon={<HomeIcon />} />
          <NavLink href="/dashboard?t=services" label="Pay" icon={<PayIcon />} />
          <NavLink href="/dashboard?t=history" label="History" icon={<HistoryIcon />} />
          <NavLink href="/dashboard?t=more" label="Settings" icon={<SettingsIcon />} />
        </div>
      </div>
    </div>
  );
};

// Navigation Link Component
interface NavLinkProps {
  href: string;
  label: string;
  icon: React.ReactNode;
  active?: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({ to, label, icon }) => (
  <RouterNavLink
    to={to}
    className={({ isActive }) =>
      `flex-1 py-2 w-[94px] flex center flex-col ${
        isActive ? '[&_path]:fill-p1 dark:[&_path]:fill-n3' : '[&_path]:stroke-[#000]/85 dark:[&_path]:stroke-n0/25 dark:opacity-70'
      }`
    }
  >
    <div>{icon}</div>
    <p
      className={`text-[0.75rem] tracking-wide font-poppins font-normal ${
        location.pathname === to || location.search.includes(to.split('?')[1])
          ? 'text-p1 dark:text-n0'
          : 'text-n7 dark:text-n0/40'
      } mt-1`}
    >
      {label}
    </p>
  </RouterNavLink>
);

// Icon Components
const HomeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
  </svg>
);

const PayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.75" stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
  </svg>
);

const HistoryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.75" stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>
);

const SettingsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.75" stroke="currentColor" className="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>
);

export default Dashboard;