import React from 'react';

const PinEntry: React.FC = () => {
  const handleButtonClick = (value: string) => {
    console.log(`Button clicked: ${value}`);
  };

  return (
      <div id="__next">
        <script>
          {`
          !function() {
            try {
              var d = document.documentElement, c = d.classList;
              c.remove('light', 'dark');
              var e = localStorage.getItem('theme');
              if ('system' === e || (!e && true)) {
                var t = '(prefers-color-scheme: dark)',
                  m = window.matchMedia(t);
                if (m.media !== t || m.matches) {
                  d.style.colorScheme = 'dark';
                  c.add('dark');
                } else {
                  d.style.colorScheme = 'light';
                  c.add('light');
                }
              } else if (e) {
                c.add(e || '');
              }
              if (e === 'light' || e === 'dark') d.style.colorScheme = e;
            } catch (e) {}
          }()
        `}
        </script>
        <div className="font-poppins bg-n0 dark:bg-n8 flex flex-col h-full">
          <div className="px-4 flex flex-col items-center">
            <button className="dark:text-n3 mt-6 flex flex-col gap-2 leading-none items-center tracking-wide text-n9 font-semibold text-caption">
            <span style={{ boxSizing: 'border-box', display: 'inline-block', overflow: 'hidden', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, position: 'relative', maxWidth: '100%' }}>
              <span style={{ boxSizing: 'border-box', display: 'block', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0, maxWidth: '100%' }}>
                <img style={{ display: 'block', maxWidth: '100%', width: 'initial', height: 'initial', background: 'none', opacity: 1, border: 0, margin: 0, padding: 0 }} alt="" aria-hidden="true" src="data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 version=%271.1%27 width=%2772%27 height=%2772%27/%3e" />
              </span>
              <img
                  alt="i"
                  src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                  decoding="async"
                  data-nimg="intrinsic"
                  style={{ borderRadius: '999px', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }}
              />
              <noscript>
                <img
                    alt="i"
                    src="/assets/avatar02.png"
                    decoding="async"
                    data-nimg="intrinsic"
                    style={{ borderRadius: '999px', position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, boxSizing: 'border-box', padding: 0, border: 'none', margin: 'auto', display: 'block', width: 0, height: 0, minWidth: '100%', maxWidth: '100%', minHeight: '100%', maxHeight: '100%' }}
                    loading="lazy"
                />
              </noscript>
            </span>
              <div className="dark:bg-[#292929] bg-n0 p-2 rounded-full">
                <span className="mx-3 tracking-wider"></span>
              </div>
            </button>
            <h4 className="pt-2 pb-1.5 text-body text-center font-bold tracking-wide text-n9 dark:text-n0 leading-tight">Welcome Back</h4>
          </div>
          <div className="flex-1 pt-4 flex flex-col">
            <div className="space-y-2 mb-4">
              <div className="flex center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-alertGreen size-4">
                  <path
                      fillRule="evenodd"
                      d="M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                      clipRule="evenodd"
                  ></path>
                </svg>
                <span className="ml-1 text-caption2 tracking-wide font-medium text-n7 dark:text-n05">Enter your PIN</span>
              </div>
              <div className="flex h-4 space-x-5 center">
                {[...Array(4)].map((_, i) => (
                    <div key={i} className="rounded size-3 dark:bg-n05/10 bg-s4/30"></div>
                ))}
              </div>
            </div>
            <div className="grid grid-cols-3 gap-0.5 mx-auto my-auto max-w-screen-md w-full grid-rows-4 min-h-80">
              {[...Array(12)].map((_, i) => (
                  <button
                      key={i}
                      className="font-poppins flex center text-body cursor-pointer font-bold text-n9 dark:text-n0"
                      onClick={() => handleButtonClick(i === 9 ? '' : i === 10 ? '0' : `${i + 1}`)}
                  >
                    {i === 9 ? '' : i === 10 ? '0' : i + 1}
                  </button>
              ))}
            </div>
          </div>
          <nav className="flex p-4 gap-2 w-full text-caption2">
            <button className="p-2 pr-3 flex gap-1 center dark:bg-[#292929] bg-n0 dark:shadow-none shadow-button rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                <path
                    fillRule="evenodd"
                    d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                    clipRule="evenodd"
                ></path>
              </svg>
              Logout
            </button>
            <button className="p-2 pr-3 flex gap-1 center dark:bg-[#292929] bg-n0 dark:shadow-none shadow-button rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-4">
                <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 0 1-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 0 1-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 0 1-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584ZM12 18a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                    clipRule="evenodd"
                ></path>
              </svg>
              Forgot PIN?
            </button>
          </nav>
        </div>
      </div>
  );
};

export default PinEntry;
