import { useState, useEffect } from 'react';
import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import api from '../lib/api';

export const useBiometricAuth = () => {
  const [isBiometricsAvailable, setIsBiometricsAvailable] = useState(false);

  useEffect(() => {
    checkBiometricSupport();
  }, []);

  const checkBiometricSupport = async () => {
    try {
      // Check if the browser supports WebAuthn
      if (!window.PublicKeyCredential) {
        setIsBiometricsAvailable(false);
        return;
      }

      // Check if platform authenticator is available (fingerprint, Face ID, etc.)
      const available = await PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
      setIsBiometricsAvailable(available);
    } catch (error) {
      console.error('Error checking biometric support:', error);
      setIsBiometricsAvailable(false);
    }
  };

  const registerBiometric = async (userId: string) => {
    try {
      const { data: options } = await api.post('/auth/biometric/register-options', { userId });
      
      const registration = await startRegistration(options);
      //const userIdUint8Array = new TextEncoder().encode(userId);
      const { data } = await api.post('/auth/biometric/register-verify', {
        userId,
        registration
      });
      
      return data;
    } catch (error) {
      console.error('Biometric registration error:', error);
      throw error;
    }
  };

  const verifyBiometric = async (userId: string) => {
    try {
      const { data: options } = await api.post('/auth/biometric/auth-options', { userId });

      const authentication = await startAuthentication(options);
      
      const { data } = await api.post('/auth/biometric/auth-verify', {
        userId,
        authentication
      });
      
      return data;
    } catch (error) {
      console.error('Biometric verification error:', error);
      throw error;
    }
  };

  return {
    isBiometricsAvailable,
    registerBiometric,
    verifyBiometric
  };
};