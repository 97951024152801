import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import api from '../lib/api';
import { useAuthStore } from '../store/authStore';

const OTPVerification: React.FC = () => {
  const [code, setCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false); // Updated for proper state management
  const navigate = useNavigate();
  const location = useLocation();
  const { setUser } = useAuthStore();
  const email = location.state?.email;

  useEffect(() => {
    try {
      const root = document.documentElement;
      root.classList.remove("light", "dark");

      const storedTheme = localStorage.getItem("theme");
      const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

      if (storedTheme === "system" || (!storedTheme && true)) {
        if (prefersDarkScheme) {
          root.style.colorScheme = "dark";
          root.classList.add("dark");
        } else {
          root.style.colorScheme = "light";
          root.classList.add("light");
        }
      } else if (storedTheme) {
        root.style.colorScheme = storedTheme;
        root.classList.add(storedTheme);
      }
    } catch (error) {
      console.error("Error applying theme:", error);
    }
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loader when OTP verification starts
    try {
      const response = await api.post('/auth/verify', { email, code });
      localStorage.setItem('token', response.data.token);
      setUser(response.data.user);
      navigate('/dashboard');
    } catch (error) {
      toast.error('Invalid verification code');
    } finally {
      setIsSubmitting(false); // Stop loader after verification
    }
  };

  const handleBack = () => {
    navigate("/auth/email");
  };

  return (
      <div className="flex flex-col relative font-poppins flex-1 w-full min-h-screen mx-auto">
        <button onClick={handleBack} className="size-14 flex center absolute">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="4"
              stroke="currentColor"
              className="size-6"
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>
        <div className="pt-16 px-4 flex-1 flex items-center">
          <div className="gap-6 grid w-full">
            <h4 className="text-body font-semibold tracking-wider">
              Enter the OTP sent to your email
            </h4>
            <form onSubmit={handleSubmit}>
              <input
                  placeholder="Enter OTP"
                  type="tel"
                  maxLength={6}
                  autoFocus
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="rounded -outline-offset-2 block w-full h-14 px-4 focus:outline-p1 focus:outline-4 outline-none box-border text-body3 font-semibold tracking-wide"
              />
              <div className="mt-6 flex center">
                <button
                    type="submit"
                    disabled={code.length !== 6 || isSubmitting}
                    className={`w-full relative text-center shadow-button h-16 rounded-full flex center font-poppins font-semibold tracking-wider ${
                        code.length === 6 && !isSubmitting
                            ? "bg-purple-600 text-white"
                            : "text-[#000]/70 dark:text-n05/90 dark:bg-[#292929]"
                    }`}
                >
                  {isSubmitting ? (
                      <div className="loader" />
                  ) : (
                      "Verify"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default OTPVerification;
