import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../lib/api';

const EmailForm: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark' | 'system'>('system');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post('/auth/email', { email });
      navigate('/auth/verify', { state: { email } });
    } catch (error) {
      console.error('Failed to send verification code', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    try {
      const root = document.documentElement;
      const savedTheme = localStorage.getItem('theme') as 'light' | 'dark' | 'system' | null;

      if (savedTheme === 'system' || (!savedTheme && true)) {
        const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

        if (darkMediaQuery.matches) {
          root.style.colorScheme = 'dark';
          root.classList.add('dark');
          root.classList.remove('light');
        } else {
          root.style.colorScheme = 'light';
          root.classList.add('light');
          root.classList.remove('dark');
        }

        darkMediaQuery.addEventListener('change', (e) => {
          if (e.matches) {
            root.style.colorScheme = 'dark';
            root.classList.add('dark');
            root.classList.remove('light');
          } else {
            root.style.colorScheme = 'light';
            root.classList.add('light');
            root.classList.remove('dark');
          }
        });
      } else if (savedTheme) {
        root.classList.add(savedTheme);
        root.style.colorScheme = savedTheme;
      }

      if (savedTheme) setTheme(savedTheme);
    } catch (error) {
      console.error('Error setting theme:', error);
    }
  }, []);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleBack = () => {
    navigate('/'); // Replace '/' with your home page route
  };

  const isEmailValid = email.includes('@') && email.includes('.');

  return (
      <div className="flex flex-col relative font-poppins flex-1 w-full min-h-screen mx-auto">
        <button className="size-14 flex center absolute" onClick={handleBack}>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="4"
              stroke="currentColor"
              className="size-6"
          >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        </button>

        <div className="pt-16 px-4 flex-1 flex items-center">
          <div className="gap-6 grid w-full">
            <h4 className="text-body font-semibold tracking-wider">What is your email?</h4>
            <form onSubmit={handleSubmit}>
              <input
                  placeholder="Email"
                  autoFocus
                  type="email"
                  className="rounded lowercase -outline-offset-2 block w-full h-14 px-4 focus:outline-p1 focus:outline-4 outline-none box-border text-body3 font-semibold tracking-wide"
                  value={email}
                  onChange={handleEmailChange}
              />
              <div className="mt-6 flex center">
                <button
                    type="submit"
                    disabled={!isEmailValid || loading}
                    className={`w-full relative text-center shadow-button h-16 rounded-full flex center font-poppins font-semibold tracking-wider ${
                        isEmailValid ? 'bg-purple-600 text-white' : 'text-[#000]/70 dark:text-n05/90 dark:bg-[#292929]'
                    }`}
                >
                  {loading ? (
                      <div className="loader" />
                  ) : (
                      'Continue'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
};

export default EmailForm;
